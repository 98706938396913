/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-08
 *
 * 全局路由
 */
import { createRouter, createWebHashHistory } from "vue-router"

const routes = [{
  name: 'home',
  path: '/',
  component: () => import('@/views/layout/index.vue'),
  redirect: '/index/index',
  meta: { title: '首页' },
  children: [{
    path: '/index/index',
    component: () => import('@/views/index/index.vue'),
    name: 'index',
    meta: { title: '首页' },
  },
  {
    path: '/project/list',
    component: () => import('@/views/project/list.vue'),
    name: 'project_list',
    meta: { title: '案例列表' },
  },
  {
    path: '/project/view',
    component: () => import('@/views/project/view.vue'),
    name: 'project_view',
    meta: { title: '案例详情' },
  },
  {
    path: '/demand/list',
    component: () => import('@/views/demand/list.vue'),
    name: 'demand_list',
    meta: { title: '方案列表' },
  },
  {
    path: '/demand/view',
    component: () => import('@/views/demand/view.vue'),
    name: 'demand_view',
    meta: { title: '方案详情' },
  },
  {
    path: '/about',
    component: () => import('@/views/about/index.vue'),
    name: 'about',
    meta: { title: '关于我们' },
  },
  {
    path:'/404',
    component:() =>import('@/views/error/404.vue'),
    name:'404',
    meta:{ title:'页面不存在' },
  },
  {
    path: '/500',
    component: () => import("@/views/error/500.vue"),
    name: '500',
    meta: { title: '500错误' },
  }]
}]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: () => ({top: 0}),
  routes
})

/**
 * 前置路由守卫
 */
router.beforeEach((to, from, next) => {
  // 设置网站标题
  setWebTitle(to)

  next()
})

/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-13
 *
 * 设置网站标题
 *
 * @return {[type]}
 */
function setWebTitle(to) {
  // 动态设置当前页面title
  const title = import.meta.env.VITE_APP_TITLE;

  document.title = to.meta.title ? `${to.meta.title} - ${title}` : title;
}

export default router
