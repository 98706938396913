/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-12
 *
 * 存储组合钩子
 */
import { getBaseInfo } from "@/store/modules/base";
import { getSystemInfo } from "@/store/modules/system";


export const useStore = () => {

  /**
   * 基础存储对象
   */
  const base = getBaseInfo();

  /**
   * 系统存储对象
   */
  const system = getSystemInfo();

  return {
    base,
    system,
  }
}
