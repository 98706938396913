/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-24
 *
 * 全局入口
 */
import App from '@/App'
import router from '@/router' // TODO: 后期优化
import { createApp } from 'vue'

import store from '@/store' // TODO: 后期优化
import '@/assets/css/style.scss' // 公共CSS
import "@/assets/fonts/iconfont.css"; // 阿里图标
import setupAxios from "@/plugins/axios"; // 全局引入请求封装对象
import setupElementPlus from "@/plugins/elementplus"; // 全局引入elementplus

const app = createApp(App)

app.use(router)
    .use(setupElementPlus)
    .use(store)
    .use(setupAxios, { router, opt: "$http" })
    .mount('#app')
