/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-13
 *
 * 系统信息存储器
 */
export const getSystemInfo = defineStore("getSystemInfo", {
  state: () => ({
    webinfo: [],
    contactinfo: [],
  }),
  getters: {
    website_logo: state => state.webinfo.logo,
    website_chinese_title: state => state.webinfo.web_chinese_name,
    website_english_title: state => state.webinfo.web_english_name,
    website_description: state => state.webinfo.description,
    copyright: state => state.webinfo.copyright,

    contact_github: state => state.contactinfo.contact_github,
    contact_gitee: state => state.contactinfo.contact_gitee,
    contact_weibo: state => state.contactinfo.contact_weibo,
    contact_wechat: state => state.contactinfo.contact_wechat,
    contact_qq: state => state.contactinfo.contact_qq,
  },
  actions: {
    async initSystemInfo() {
      if(Object.keys(this.webinfo).length){
        return
      }
      await $http({
        url: $http.adornUrl(`/system/kernel`),
        method: 'get',
        params: $http.adornParams(),
      }).then(({ data }) => {
        if (data && data.status === 200) {
          this.webinfo = data.data
        }
      })
    },
    async initContactInfo() {
      if(Object.keys(this.contactinfo).length){
        return
      }
      await $http({
        url: $http.adornUrl(`/common/contact/data`),
        method: 'get',
        params: $http.adornParams(),
      }).then(({ data }) => {
        if (data && data.status === 200) {
          this.contactinfo = data.data
        }
      })
    },
    clearData() {
      this.webinfo = []
      this.contactinfo = []
    },
  },
  persist: {
    key: "systemInfoStorage", // 持久化存入的key
    storage: localStorage // 持久化方式
  }
});

